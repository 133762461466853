// hover (opacity)
//----------------------//
.header-logo,
.slick-arrow {
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
}

// hover (色変化)
//----------------------//

.more-btn {
  &:hover {
    background-color: $navy;
    color: $white;
    &::after {
      background-image: url(../images/icon_arrow_right_white.svg);
    }
  }
}

// hover時、背景色青
.news-list a {
  &:hover {
		background-color: $purple01;
	}
}

// hover時、文字色青
.header-nav-link,
.footer-nav a,
.column-list a {
  &:hover {
    color: $blue;
  }
}

// kv-news-box
.kv-news-text {
  transition: 0.3s;
}
.kv-news-box {
  &:hover {
    .kv-news-text {
      color: $blue05;
      transition: 0.3s;
    }
  }
}

// hover (ボタン)
//----------------------//
.bg-gradient.animation {
  transition: all 0.4s;
  background: linear-gradient(100deg, $navy 0%, $blue 37%, $navy 100%);
  background-size: 200% auto;
  background-position: 1% 50%;
  &:hover {
    background-position: 70% 50%;
    box-shadow: 0 5px 10px $gray04;
  }
}

.cta-section-btn {
  background-color: $yellow01;
  &:hover {
    background-color: $yellow03;
    transition: 0.3s;
  }
}
