// kv
.kv {
  &-inner {
    padding: 300px 40px 40px 40px;
    border-radius: 20px;
    box-sizing: border-box;
    background-image: url(../images/kv.jpg);
    background-size: cover;
    background-position: 0 3%;
    background-repeat: no-repeat;
  }
  &-content {
    max-width: 480px;
    margin-left: auto;
  }
  &-main-box {
    position: relative;
    padding: 32px 45px 20px;
    border-radius: 10px;
    background-color: $white;
  }
  &-fukidashi {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    right: -10px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    img {
      width: 90px;
    }
  }
  &-text {
    color: $navy;
    font-size: 18px;
    letter-spacing: 0.12em;
  }
  &-copy {
    color: $navy;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1.4;
  }
  &-news {
    &-box {
      display: block;
      padding: 18px 10px 18px 20px;
      margin-top: 20px;
      border-radius: 10px;
    }
    &-info {
      display: flex;
      align-self: center;
      gap: 25px;
      letter-spacing: 0.04em;
    }
    &-date {
      font-size: 13px;
    }
    &-tag {
      padding: 1px 12px;
      border-radius: 4px;
      background-color: $navy;
      color: $white;
      font-size: 11px;
    }
    &-text {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

// about
.top-about {
  padding-top: 150px;
  &-box {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 60px;
    border-radius: 20px;
    background-color: $white;
  }
  &-image {
    width: 540px;
    height: 420px;
    border-radius: 20px;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    flex: 1;
    margin-bottom: -30px;
    p {
      margin-bottom: 30px;
    }
  }
  // &-movie {
  //   &-wrap {
  //     margin-top: 100px;
  //   }
  //   &-title {
  //     position: relative;
  //     width: max-content;
  //     margin: 0 auto 10px;
  //     font-size: 18px;
  //     &::before,
  //     &::after {
  //       content: '';
  //       position: absolute;
  //       bottom: 30px;
  //       width: 38px;
  //       height: 27px;
  //       background-size: contain;
  //       background-position: center;
  //       background-repeat: no-repeat;
  //     }
  //     &::before {
  //       left: -50px;
  //       background-image: url(../images/icon_movie_left.png);
  //     }
  //     &::after {
  //       right: -50px;
  //       background-image: url(../images/icon_movie_right.png);
  //     }
  //     span {
  //       font-size: 32px;
  //     }
  //     .num {
  //       font-size: 48px;
  //     }
  //   }
  //   &-text {
  //     text-align: center;
  //   }
  //   position: relative;
  //   max-width: 720px;
  //   margin: 40px auto 0;
  //   .video {
  //     width: 100%;
  //   }
  //   .play-btn {
  //     position: absolute;
  //     top: 0;
  //     .btn {
  //       position: absolute;
  //       top: 50%;
  //       left: 50%;
  //       transform: translate(-50%, -50%);
  //       width: 80px;
  //       transition: 0.3s;
  //     }
  //     &:hover {
  //       .btn {
  //         opacity: 0.9;
  //         transition: 0.3s;
  //       }
  //     }
  //     &.hidden {
  //       display: none;
  //     }
  //   }
  // }
}

// reason
.top-reason {
  &-item {
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    background-color: $white;
    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
    &:nth-child(n+2) {
      margin-top: 100px;
    }
  }
  &-text {
    flex: 1;
    padding: 30px 50px 45px;
  }
  &-number {
    display: inline-block;
    position: relative;
    color: $navy;
    .en,
    .num {
      font-weight: 700;
    }
    .en {
      font-size: 32px;
    }
    .num {
      font-size: 48px;
    }
    &::after {
      content: '';
      position: absolute;
      right: -145px;
      bottom: 36px;
      width: 120px;
      height: 1px;
      background-color: $navy;
    }
  }
  &-title {
    margin-top: 6px;
    font-size: 24px;
  }
  &-lead {
    margin-top: 10px;
  }
  &-image {
    width: 540px;
    &02 {
      height: 430px;
    }
    &03 {
      height: 360px;
    }
    img {
      min-height: 100%;
      object-fit: cover;
    }
  }
}

// cta
.cta {
  &-title {
    position: relative;
    width: max-content;
    margin: 0 auto 50px;
    color: $white;
    font-size: 32px;
    text-align: center;
    line-height: 1.5;
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      width: 107px;
      height: 82px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &::before {
      left: -105px;
      background-image: url(../images/icon_cta_left.png);
    }
    &::after {
      right: -105px;
      background-image: url(../images/icon_cta_right.png);
    }
  }
  &-free {
    &-box {
      position: relative;
      padding: 40px 80px 50px;
      border-radius: 20px;
      background-color: $white;
    }
    &-fukidashi {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -25px;
      left: -80px;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      font-size: 25px;
      letter-spacing: 0.12em;
      line-height: 1.2;
      transform: rotate(-10deg);
    }
    &-title {
      margin-bottom: 10px;
      font-size: 20px;
      text-align: center;
      .large {
        font-size: 1.6em;
      }
    }
  }
  &-notes {
    margin: 60px 0;
    color: $white;
    text-align: center;
  }
}

// case
.top-case {
  &-item {
    overflow: hidden;
    width: 480px;
    margin: 0 40px 10px;
    border-radius: 20px;
    background-color: $white;
    &-bottom {
      padding: 30px 24px;
    }
    &-name {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $gray04;
      font-size: 18px;
      text-align: center;
      line-height: 1.5;
    }
    &-text {
      color: $black02;
      font-size: 14px;
    }
  }
}

// price
.price {
  &-tab {
    &-btns {
      display: flex;
      justify-content: center;
      width: 560px;
      height: 80px;
      margin: 0 auto 50px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: $white;
      color: $navy;
      transition: 0.3s;
      &-text {
        position: relative;
        padding-left: 24px;
        &::before {
          content: '';
          position: absolute;
          top: 47%;
          left: 0;
          width: 12px;
          height: 7px;
          background-image: url(../images/icon_arrow_bottom_navy.svg);
          background-position: center 0;
          background-size: cover;
          background-repeat: no-repeat;
          transition: 0.3s;
        }
      }
      &.active {
        background-color: $navy;
        color: $white;
        .price-tab-btn-text {
          &::before {
            content: '';
            top: 67%;
            background-image: url(../images/icon_arrow_bottom_white.svg);
          }
        }
      }
      @include hover() {
        &:hover {
          background-color: $navy;
          color: $white;
          transition: 0.3s;
          .price-tab-btn-text {
            &::before {
              content: '';
              top: 67%;
              background-image: url(../images/icon_arrow_bottom_white.svg);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
  &-item {
    display: none;
    padding: 50px;
    border: 2px solid $navy;
    border-radius: 20px;
    background-color: $white;
    &.active {
      display: block;
    }
    &-group {
      &-subscription {
        display: flex;
        gap: 25px;
      }
      width: calc((100% - 25px)/2);
      &-event {
        margin-top: 30px;
        padding: 36px 30px;
        border-radius: 10px;
        background-color: $gray05;
        &-title {
          margin-bottom: 20px;
          color: $navy;
          font-size: 20px;
          text-align: center;
        }
      }
    }
    &-title {
      margin-bottom: 30px;
      padding-bottom: 5px;
      border-bottom: 1px solid $white;
      color: $white;
      font-size: 28px;
      letter-spacing: 0.12em;
      text-align: center;
      &.navy {
        border-bottom: 1px solid $navy;
        color: $navy;
      }
    }
    &-notes {
      margin-top: 16px;
    }
    &-text {
      margin-top: 30px;
    }
    &-example {
      &-box {
        padding: 30px 36px;
        border-radius: 10px;
        background-color: $gray05;
      }
      &-table {
        margin: 12px 0;
        padding: 12px 24px 18px;
        border-radius: 10px;
        background-color: $white;
        dl {
          display: flex;
          align-items: center;
          padding: 5px 0;
          border-bottom: 1px solid $gray04;
        }
        dt {
          width: 90px;
        }
        dd {
          flex: 1;
          color: $blue04;
        }
      }
      &-notes {
        color: $black02;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.5;
      }
    }
    &-alert-list {
      margin-top: 30px;
      font-size: 14px;
    }
    &-image {
      overflow: hidden;
      max-width: 460px;
      margin: 0 auto 30px;
      border-radius: 10px;
    }
    .more-btn {
      margin-top: 40px;
    }
  }
}

// faq
// .faq {
//   &-item {
//     position: relative;
//     padding: 20px 70px 20px 20px;
//     border-radius: 8px;
//     overflow: hidden;
//     background-color: $white;
//     cursor: pointer;
//     &:nth-child(n+2) {
//       margin-top: 20px;
//     }
//     &.open {
//       .faq-btn {
//         background-color: $gray02;
//         span {
//           &:last-child {
//             opacity: 0;
//           }
//         }
//       }
//     }
//   }
//   &-question {
//     &-box {
//       display: flex;
//       gap: 15px;
//       .en {
//         color: $blue05;
//       }
//     }
//   }
//   &-answer {
//     &-box {
//       display: none;
//       margin-top: 20px;
//       .en {
//         color: $orange;
//       }
//       &-inner {
//         display: flex;
//         gap: 15px;
//       }
//     }
//   }
//   &-btn {
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     width: 36px;
//     height: 36px;
//     border-radius: 50%;
//     background-color: $orange;
//     span {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       background-color: $white;
//       &:first-child {
//         width: 14px;
//         height: 1px;
//       }
//       &:last-child {
//         width: 1px;
//         height: 14px;
//       }
//     }
//     &.open {
//       background-color: $gray02;
//       span {
//         &:last-child {
//           opacity: 0;
//         }
//       }
//     }
//   }
// }

// news
.top-news {
  background-color: $white;
}
.news {
  &-list {
    li {
      border-radius: 8px;
      overflow: hidden;
      background-color: $white;
      &:nth-child(n+2) {
        margin-top: 20px;
      }
      a {
        display: flex;
        align-items: baseline;
        gap: 30px;
        padding: 20px 50px 20px 20px;
        line-height: 1.5;
      }
      .date {
        flex-shrink: 0;
        width: 100px;
      }
    }
  }
}

//column
.top-column {
  background-image: url(../images/bg_column.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.column {
  &-list {
    display: flex;
    gap: 30px;
    li {
      width: calc((100% - 60px)/3);
      border-radius: 8px;
      overflow: hidden;
      a {
        display: block;
        background-color: $white;
      }
    }
  }
  &-text {
    padding: 16px 16px 20px;
  }
  &-title {
    margin-top: 5px;
    line-height: 1.5;
  }
}

// recruit
.top-recruit {
  padding-bottom: 0;
  &-inner {
    display: flex;
    gap: 30px;
  }
  &-image {
    width: 680px;
  }
}
