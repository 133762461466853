//base
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400;500;700|Ubuntu&subset=japanese&display=swap');

* {
  box-sizing: border-box;
  word-break: break-all;
}
html {
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}
body {
  background-image: url(../images/bg_pattern.jpg);
	background-size: contain;
	background-repeat: repeat;
  font-family: 'Zen Maru Gothic', serif;
	font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  color: $black02;
  line-height: 2;
  font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h2,
h3,
dl,
small {
  letter-spacing: 0.08em;
}
a {
	color: $black01;
	text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
  }
}
img {
  display: block;
	max-width: 100%;
  vertical-align: bottom;
}
ol {
  padding-left: 20px;
  list-style-type: decimal;
}
strong {
  font-weight: bold;
}
sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

// フォント
.en,
.num {
  font-family: "montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}
