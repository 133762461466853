@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400;500;700|Ubuntu&subset=japanese&display=swap");
.cl-black {
  color: #000;
}

.cl-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.cl-navy {
  background-color: #0D274E;
}

.bg-navy {
  background-color: #0D274E;
}

.bg-blue {
  background-color: #1D5CBB;
}

.bg-gray05 {
  background-color: #f4f6f9;
}

.cl-orange {
  color: #ff4747;
}

.bg-orange {
  background-color: #ff4747;
}

.bg-purple02 {
  background-color: #efe0f2;
}

.bg-darkred {
  background-color: #cd2d2d;
}

.bg-yellow02 {
  background-color: #F9F871;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%;
}

body {
  background-image: url(../images/bg_pattern.jpg);
  background-size: contain;
  background-repeat: repeat;
  font-family: "Zen Maru Gothic", serif;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  color: #333;
  line-height: 2;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h2,
h3,
dl,
small {
  letter-spacing: 0.08em;
}

a {
  color: #000;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}
a:hover {
  transition: 0.3s;
}

img {
  display: block;
  max-width: 100%;
  vertical-align: bottom;
}

ol {
  padding-left: 20px;
  list-style-type: decimal;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

.en,
.num {
  font-family: "montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

a {
  color: #0D274E;
  text-decoration: none;
}

#wrapper {
  overflow: hidden;
}

.inner-s,
.inner-xs,
.inner-m,
.inner-l {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: content-box;
}

.inner-xs {
  max-width: 640px;
}

.inner-s {
  max-width: 900px;
}

.inner-m {
  max-width: 1024px;
}

.inner-l {
  max-width: 1200px;
}

.top-case .inner-l {
  padding: 0;
}

section {
  padding: 100px 0;
}

.section-title {
  margin-bottom: 60px;
  text-align: center;
}
.section-title .en,
.section-title .jp,
.section-title .border {
  display: block;
}
.section-title .en {
  color: #1D5CBB;
  font-size: 12px;
  text-indent: 0.08em;
}
.section-title .jp {
  color: #0D274E;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 0.16em;
  text-indent: 0.16em;
}
.section-title .jp .small {
  font-size: 0.6em;
  letter-spacing: 0.04em;
}
.section-title .border {
  width: 15px;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 16px;
  margin-right: auto;
  margin-left: auto;
  background-color: #6e6e6e;
}
.section-title.left {
  text-align: left;
}
.section-title.left .border {
  margin-bottom: 0;
  margin-left: 0;
}
.section-title.white .en,
.section-title.white .jp {
  color: #fff;
}
.section-title.white .border {
  margin-bottom: 16px;
  background-color: #fff;
}
.section-title.top-recruit-title {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.section-title.top-recruit-title .large-en {
  position: absolute;
  z-index: -1;
  top: -64px;
  left: 24px;
  color: #fdf2ff;
  font-size: 94px;
  line-height: 1;
  white-space: nowrap;
}

.bg-pattern {
  background-image: url(../images/bg_pattern.jpg);
  background-size: contain;
  background-repeat: repeat;
}

.more-btn {
  display: block;
  width: fit-content;
  margin: 60px auto 0;
  padding: 16px 60px 16px 20px;
  border: 1px solid #0D274E;
  border-radius: 8px;
  background-color: #fff;
  color: #0D274E;
  letter-spacing: 0.08em;
}
.more-btn.left {
  margin: 30px 0 0 0;
}

.cta-btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 16px 50px 16px 12px;
  border-radius: 8px;
  letter-spacing: 0.08em;
}
.cta-btn .icon {
  width: 35px;
}
.cta-btn.center {
  display: flex;
  width: fit-content;
  margin: 0 auto;
}

.arrow {
  position: relative;
}
.arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../images/icon_arrow_blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.arrow.white::after {
  background-image: url(../images/icon_arrow_white.png);
}
.arrow.black::after {
  background-image: url(../images/icon_arrow_black.svg);
}

.date {
  color: #AAA;
  font-size: 14px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.1529411765) 0px 3px 6px;
}

.underline {
  background: linear-gradient(to bottom, transparent 70%, rgba(255, 71, 71, 0.3) 70%);
}
.underline-dark {
  background: linear-gradient(to bottom, transparent 70%, rgba(249, 248, 113, 0.75) 70%);
}

.common-point-list {
  width: 420px;
  margin-top: 20px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #efe0f2;
  letter-spacing: 0.08em;
}
.common-point-list.center {
  margin: 30px auto 0;
}
.common-point-list li {
  position: relative;
  padding-left: 1.7em;
  color: #0D274E;
  font-size: 14px;
  line-height: 1.5;
}
.common-point-list li .sub {
  font-size: 0.85em;
}
.common-point-list li::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  background-image: url(../images/icon_check.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.common-point-list li:nth-child(n+2) {
  margin-top: 16px;
}

.common-circle-list li {
  position: relative;
  padding-left: 1.4em;
  color: #0D274E;
  letter-spacing: 0.08em;
}
.common-circle-list li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #0D274E;
}
.common-circle-list li:nth-child(n+2) {
  margin-top: 10px;
}

.common-table {
  overflow: hidden;
  border-radius: 10px;
}
.common-table.border {
  border: 1px solid #D8D8D8;
}
.common-table dl {
  display: flex;
  line-height: 1.3;
}
.common-table dl:nth-child(n+2) dt {
  border-top: 1px solid #fff;
}
.common-table dl:nth-child(n+2) dd {
  border-top: 1px solid #D8D8D8;
}
.common-table dt,
.common-table dd {
  padding: 20px 18px;
}
.common-table dt {
  width: 140px;
  background-color: #0D274E;
  color: #fff;
}
.common-table dt.size-s {
  width: 140px;
}
.common-table dt.size-m {
  width: 180px;
}
.common-table dd {
  flex: 1;
  background-color: #fff;
}
.common-table dd .sub {
  color: #333;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
}

.price-item-group-event .common-table {
  font-size: 17px;
}
.price-item-group-event .common-table dt,
.price-item-group-event .common-table dd {
  padding: 28px 18px;
}
.price-item-group-event .common-table dt {
  width: 50%;
}

.header {
  padding: 30px 0;
}
.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 8px;
}
.header-logo {
  width: 100px;
}
.header-logo:hover {
  opacity: 0.75;
}
.header-nav, .header-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.header-nav-link {
  display: block;
  text-align: center;
  line-height: 1.5;
}
.header-nav-link .jp,
.header-nav-link .en {
  display: block;
}
.header-nav-link .jp {
  margin-top: 8px;
  font-size: 15px;
}
.header-nav-link .en {
  color: #1D5CBB;
  font-size: 10px;
  letter-spacing: 0.08em;
}
.header-cta-btn {
  padding: 12px 18px 10px;
  border-radius: 8px;
  color: #fff;
}
.header-cta-btn .icon {
  width: 60px;
  margin: 6px auto 3px;
}

.footer-main {
  padding: 90px 0;
}
.footer-main-inner {
  display: flex;
  justify-content: space-between;
}
.footer-logo img {
  width: 100px;
}
.footer-logo p {
  margin-top: 20px;
  color: #0D274E;
  font-size: 15px;
}
.footer-nav {
  display: flex;
  gap: 40px;
}
.footer-nav ul li + li {
  margin-top: 10px;
}
.footer-copyright {
  padding: 16px 0;
  text-align: center;
}
.footer-copyright small {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
}

.kv-inner {
  padding: 300px 40px 40px 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background-image: url(../images/kv.jpg);
  background-size: cover;
  background-position: 0 3%;
  background-repeat: no-repeat;
}
.kv-content {
  max-width: 480px;
  margin-left: auto;
}
.kv-main-box {
  position: relative;
  padding: 32px 45px 20px;
  border-radius: 10px;
  background-color: #fff;
}
.kv-fukidashi {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  right: -10px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.kv-fukidashi img {
  width: 90px;
}
.kv-text {
  color: #0D274E;
  font-size: 18px;
  letter-spacing: 0.12em;
}
.kv-copy {
  color: #0D274E;
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 1.4;
}
.kv-news-box {
  display: block;
  padding: 18px 10px 18px 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.kv-news-info {
  display: flex;
  align-self: center;
  gap: 25px;
  letter-spacing: 0.04em;
}
.kv-news-date {
  font-size: 13px;
}
.kv-news-tag {
  padding: 1px 12px;
  border-radius: 4px;
  background-color: #0D274E;
  color: #fff;
  font-size: 11px;
}
.kv-news-text {
  margin-top: 5px;
  font-size: 14px;
}

.top-about {
  padding-top: 150px;
}
.top-about-box {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 60px;
  border-radius: 20px;
  background-color: #fff;
}
.top-about-image {
  width: 540px;
  height: 420px;
  border-radius: 20px;
  overflow: hidden;
}
.top-about-image img {
  height: 100%;
  object-fit: cover;
}
.top-about-text {
  flex: 1;
  margin-bottom: -30px;
}
.top-about-text p {
  margin-bottom: 30px;
}

.top-reason-item {
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  background-color: #fff;
}
.top-reason-item:nth-child(2n) {
  flex-direction: row-reverse;
}
.top-reason-item:nth-child(n+2) {
  margin-top: 100px;
}
.top-reason-text {
  flex: 1;
  padding: 30px 50px 45px;
}
.top-reason-number {
  display: inline-block;
  position: relative;
  color: #0D274E;
}
.top-reason-number .en,
.top-reason-number .num {
  font-weight: 700;
}
.top-reason-number .en {
  font-size: 32px;
}
.top-reason-number .num {
  font-size: 48px;
}
.top-reason-number::after {
  content: "";
  position: absolute;
  right: -145px;
  bottom: 36px;
  width: 120px;
  height: 1px;
  background-color: #0D274E;
}
.top-reason-title {
  margin-top: 6px;
  font-size: 24px;
}
.top-reason-lead {
  margin-top: 10px;
}
.top-reason-image {
  width: 540px;
}
.top-reason-image02 {
  height: 430px;
}
.top-reason-image03 {
  height: 360px;
}
.top-reason-image img {
  min-height: 100%;
  object-fit: cover;
}

.cta-title {
  position: relative;
  width: max-content;
  margin: 0 auto 50px;
  color: #fff;
  font-size: 32px;
  text-align: center;
  line-height: 1.5;
}
.cta-title::before, .cta-title::after {
  content: "";
  position: absolute;
  bottom: 10px;
  width: 107px;
  height: 82px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cta-title::before {
  left: -105px;
  background-image: url(../images/icon_cta_left.png);
}
.cta-title::after {
  right: -105px;
  background-image: url(../images/icon_cta_right.png);
}
.cta-free-box {
  position: relative;
  padding: 40px 80px 50px;
  border-radius: 20px;
  background-color: #fff;
}
.cta-free-fukidashi {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: -80px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  font-size: 25px;
  letter-spacing: 0.12em;
  line-height: 1.2;
  transform: rotate(-10deg);
}
.cta-free-title {
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
}
.cta-free-title .large {
  font-size: 1.6em;
}
.cta-notes {
  margin: 60px 0;
  color: #fff;
  text-align: center;
}

.top-case-item {
  overflow: hidden;
  width: 480px;
  margin: 0 40px 10px;
  border-radius: 20px;
  background-color: #fff;
}
.top-case-item-bottom {
  padding: 30px 24px;
}
.top-case-item-name {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #D8D8D8;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
}
.top-case-item-text {
  color: #333;
  font-size: 14px;
}

.price-tab-btns {
  display: flex;
  justify-content: center;
  width: 560px;
  height: 80px;
  margin: 0 auto 50px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.price-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: #fff;
  color: #0D274E;
  transition: 0.3s;
}
.price-tab-btn-text {
  position: relative;
  padding-left: 24px;
}
.price-tab-btn-text::before {
  content: "";
  position: absolute;
  top: 47%;
  left: 0;
  width: 12px;
  height: 7px;
  background-image: url(../images/icon_arrow_bottom_navy.svg);
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.3s;
}
.price-tab-btn.active {
  background-color: #0D274E;
  color: #fff;
}
.price-tab-btn.active .price-tab-btn-text::before {
  content: "";
  top: 67%;
  background-image: url(../images/icon_arrow_bottom_white.svg);
}
@media (hover: hover) and (pointer: fine) {
  .price-tab-btn:hover {
    background-color: #0D274E;
    color: #fff;
    transition: 0.3s;
  }
  .price-tab-btn:hover .price-tab-btn-text::before {
    content: "";
    top: 67%;
    background-image: url(../images/icon_arrow_bottom_white.svg);
    transition: 0.3s;
  }
}
.price-item {
  display: none;
  padding: 50px;
  border: 2px solid #0D274E;
  border-radius: 20px;
  background-color: #fff;
}
.price-item.active {
  display: block;
}
.price-item-group {
  width: calc((100% - 25px) / 2);
}
.price-item-group-subscription {
  display: flex;
  gap: 25px;
}
.price-item-group-event {
  margin-top: 30px;
  padding: 36px 30px;
  border-radius: 10px;
  background-color: #f4f6f9;
}
.price-item-group-event-title {
  margin-bottom: 20px;
  color: #0D274E;
  font-size: 20px;
  text-align: center;
}
.price-item-title {
  margin-bottom: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 28px;
  letter-spacing: 0.12em;
  text-align: center;
}
.price-item-title.navy {
  border-bottom: 1px solid #0D274E;
  color: #0D274E;
}
.price-item-notes {
  margin-top: 16px;
}
.price-item-text {
  margin-top: 30px;
}
.price-item-example-box {
  padding: 30px 36px;
  border-radius: 10px;
  background-color: #f4f6f9;
}
.price-item-example-table {
  margin: 12px 0;
  padding: 12px 24px 18px;
  border-radius: 10px;
  background-color: #fff;
}
.price-item-example-table dl {
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #D8D8D8;
}
.price-item-example-table dt {
  width: 90px;
}
.price-item-example-table dd {
  flex: 1;
  color: #1F55A8;
}
.price-item-example-notes {
  color: #333;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
}
.price-item-alert-list {
  margin-top: 30px;
  font-size: 14px;
}
.price-item-image {
  overflow: hidden;
  max-width: 460px;
  margin: 0 auto 30px;
  border-radius: 10px;
}
.price-item .more-btn {
  margin-top: 40px;
}

.top-news {
  background-color: #fff;
}

.news-list li {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}
.news-list li:nth-child(n+2) {
  margin-top: 20px;
}
.news-list li a {
  display: flex;
  align-items: baseline;
  gap: 30px;
  padding: 20px 50px 20px 20px;
  line-height: 1.5;
}
.news-list li .date {
  flex-shrink: 0;
  width: 100px;
}

.top-column {
  background-image: url(../images/bg_column.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.column-list {
  display: flex;
  gap: 30px;
}
.column-list li {
  width: calc((100% - 60px) / 3);
  border-radius: 8px;
  overflow: hidden;
}
.column-list li a {
  display: block;
  background-color: #fff;
}
.column-text {
  padding: 16px 16px 20px;
}
.column-title {
  margin-top: 5px;
  line-height: 1.5;
}

.top-recruit {
  padding-bottom: 0;
}
.top-recruit-inner {
  display: flex;
  gap: 30px;
}
.top-recruit-image {
  width: 680px;
}

.slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #0D274E;
  cursor: pointer;
}
.slick-arrow img {
  width: 20px;
}
.slick-prev {
  left: 17.5%;
}
.slick-next {
  right: 17.5%;
}

.slick-list {
  overflow: visible;
}

.header-logo,
.slick-arrow {
  transition: 0.3s;
}
.header-logo:hover,
.slick-arrow:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.more-btn:hover {
  background-color: #0D274E;
  color: #fff;
}
.more-btn:hover::after {
  background-image: url(../images/icon_arrow_right_white.svg);
}

.news-list a:hover {
  background-color: #efe0f2;
}

.header-nav-link:hover,
.footer-nav a:hover,
.column-list a:hover {
  color: #1D5CBB;
}

.kv-news-text {
  transition: 0.3s;
}

.kv-news-box:hover .kv-news-text {
  color: #1065E5;
  transition: 0.3s;
}

.bg-gradient.animation {
  transition: all 0.4s;
  background: linear-gradient(100deg, #0D274E 0%, #1D5CBB 37%, #0D274E 100%);
  background-size: 200% auto;
  background-position: 1% 50%;
}
.bg-gradient.animation:hover {
  background-position: 70% 50%;
  box-shadow: 0 5px 10px #D8D8D8;
}

.cta-section-btn {
  background-color: #F9F871;
}
.cta-section-btn:hover {
  background-color: #FFF7D6;
  transition: 0.3s;
}