// wrapper
#wrapper {
	overflow: hidden;
}

// inner
.inner-s,
.inner-xs,
.inner-m,
.inner-l {
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: content-box;
}
.inner-xs {
	max-width: 640px;
}
.inner-s {
	max-width: 900px;
}
.inner-m {
	max-width: 1024px;
}
.inner-l {
	max-width: 1200px;
}
.top-case {
	.inner-l {
		padding: 0;
	}
}

// section
section {
	padding: 100px 0;
}
.section-title {
	margin-bottom: 60px;
	text-align: center;
	.en,
	.jp,
	.border {
		display: block;
	}
	.en {
		color: $blue;
		font-size: 12px;
		text-indent: 0.08em;
	}
	.jp {
		color: $navy;
		font-size: 40px;
		line-height: 1.2;
		letter-spacing: 0.16em;
		text-indent: 0.16em;
		.small {
			font-size: 0.6em;
			letter-spacing: 0.04em;
		}
	}
	.border {
		width: 15px;
		height: 1px;
		margin-top: 10px;
		margin-bottom: 16px;
		margin-right: auto;
		margin-left: auto;
		background-color: $gray01;
	}
	&.left {
		text-align: left;
		.border {
			margin-bottom: 0;
			margin-left: 0;
		}
	}
	&.white {
		.en,
		.jp {
			color: $white;
		}
		.border {
			margin-bottom: 16px;
			background-color: $white;
		}
	}
	&.top-recruit-title {
		position: relative;
		z-index: 1;
		margin-bottom: 30px;
		.large-en {
			position: absolute;
			z-index: -1;
			top: -64px;
			left: 24px;
			color: $purple03;
			font-size: 94px;
			line-height: 1;
			white-space: nowrap;
		}
	}
}

//bg 
.bg-pattern {
	background-image: url(../images/bg_pattern.jpg);
	background-size: contain;
	background-repeat: repeat;
}

// btn 
.more-btn {
	display: block;
	width: fit-content;
	margin: 60px auto 0;
	padding: 16px 60px 16px 20px;
	border: 1px solid $navy;
	border-radius: 8px;
	background-color: $white;
	color: $navy;
	letter-spacing: 0.08em;
	&.left {
		margin: 30px 0 0 0;
	}
}

.cta-btn {
	display: inline-flex;
	align-items: center;
	gap: 10px;
	padding: 16px 50px 16px 12px;
	border-radius: 8px;
	letter-spacing: 0.08em;
	.icon {
		width: 35px;
	}
	&.center {
		display: flex;
		width: fit-content;
		margin: 0 auto;
	}
}

// arrow
.arrow {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 16px;
		width: 16px;
		height: 16px;
		background-image: url(../images/icon_arrow_blue.svg);
		background-size: contain;
		background-repeat: no-repeat;
	}
	&.white {
		&::after {
			background-image: url(../images/icon_arrow_white.png);
		}
	}
	&.black {
		&::after {
			background-image: url(../images/icon_arrow_black.svg);
		}
	}
}

// date
.date {
	color: $gray02;
	font-size: 14px;
}

// shadow
.shadow {
	box-shadow: $gray03 0px 3px 6px;
}

// underline
.underline {
	background: linear-gradient(
		to bottom,
		transparent 70%,
		$orange02 70%
	);
	&-dark {
		background: linear-gradient(
			to bottom,
			transparent 70%,
			$yellow02 70%
		);
	}
}

// list
.common-point-list {
	width: 420px;
	margin-top: 20px;
	padding: 20px 30px;
	border-radius: 10px;
	background-color: $purple01;
		letter-spacing: 0.08em;
	&.center {
		margin: 30px auto 0;
	}
	li {
		position: relative;
		padding-left: 1.7em;
		color: $navy;
		font-size: 14px;
		line-height: 1.5;
		.sub {
			font-size: 0.85em;
		}
		&::before {
			content: '';
			position: absolute;
			top: 4px;
			left: 0;
			width: 15px;
			height: 15px;
			background-image: url(../images/icon_check.svg);
			background-size: contain;
			background-repeat: no-repeat;
		}
		&:nth-child(n+2) {
			margin-top: 16px;
		}
	}
}
.common-circle-list {
	li {
		position:relative;
		padding-left: 1.4em;
		color: $navy;
		letter-spacing: 0.08em;
		&::before {
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background-color: $navy;
		}
		&:nth-child(n+2) {
			margin-top: 10px;
		}
	}
}

//table
.common-table {
  overflow: hidden;
  border-radius: 10px;
  &.border {
    border: 1px solid $gray04;
  }
  dl {
    display: flex;
    line-height: 1.3;
    &:nth-child(n+2) {
      dt {
        border-top: 1px solid $white;
      }
      dd {
        border-top: 1px solid $gray04;
      }
    }
  }
  dt,
  dd {
    padding: 20px 18px;
  }
  dt {
    width: 140px;
    background-color: $navy;
    color: $white;
    &.size-s {
      width: 140px;
    }
    &.size-m {
      width: 180px;
    }
  }
  dd {
    flex: 1;
    background-color: $white;
    .sub {
      color: $black02;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
.price-item-group-event {
	.common-table {
		font-size: 17px;
		dt,
		dd {
			padding: 28px 18px;
		}
		dt {
			width: 50%;
		}
	}
}

// header
.header {
	padding: 30px 0;
	&-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		background-color: $white;
		border-radius: 8px;
	}
	&-logo {
		width: 100px;
		&:hover {
			opacity: 0.75;
		}
	}
	&-nav {
		&,
		&-list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 30px;
		}
		&-link {
			display: block;
			text-align: center;
			line-height: 1.5;
			.jp,
			.en {
				display: block;
			}
			.jp {
				margin-top: 8px;
				font-size: 15px;
			}
			.en {
				color: $blue;
				font-size: 10px;
				letter-spacing: 0.08em;
			}
		}
	}
	&-cta-btn {
		padding: 12px 18px 10px;
		border-radius: 8px;
		color: $white;
		.icon {
			width: 60px;
			margin: 6px auto 3px;
		}
	}
}

// footer
.footer {
	&-main {
		padding: 90px 0;
		&-inner {
			display: flex;
			justify-content: space-between;
		}
	}
	&-logo {
		img {
			width: 100px;
		}
		p {
			margin-top: 20px;
			color: $navy;
			font-size: 15px;
		}
	}
	&-nav {
		display: flex;
		gap: 40px;
		ul {
			li {
				+li {
					margin-top: 10px;
				}
			}
		}
	}
	&-copyright {
		padding: 16px 0;
		text-align: center;
		small {
			color: $white;
			font-size: 11px;
			font-weight: 400;
		}
	}
}
