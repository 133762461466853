// colors
$white: #fff;
$black01: #000;
$black02: #333;
$gray01: #6e6e6e;
$gray02: #AAA;
$gray03: #00000027;
$gray04: #D8D8D8;
$gray05: #f4f6f9;
$navy: #0D274E;
$blue: #1D5CBB;
$blue02: #E9F2FF;
$blue03: #1B57B0;
$blue04: #1F55A8;
$blue05: #1065E5;
$blue06: #d4e6ff;
$orange: #ff4747;
$orange02: rgba(255, 71, 71, 0.3);
$darkred: #cd2d2d;
$yellow01: #F9F871;
$yellow02: rgba(249, 248, 113, 0.75);
$yellow03: #FFF7D6;
$purple01: #efe0f2;
$purple02: #c79dcf;
$purple03: #fdf2ff;
$purple04: #d8ccda;


// black
.cl-black {
  color: $black01;
}

// white
.cl-white {
  color: $white;
}
.bg-white {
  background-color: $white;
}

// navy
.cl-navy {
  background-color: $navy;
}
.bg-navy {
  background-color: $navy;
}

// blue
.bg-blue {
  background-color: $blue;
}

// gray05
.bg-gray05 {
  background-color: $gray05;
}

// orange
.cl-orange {
  color: $orange;
}
.bg-orange {
  background-color: $orange;
}

// purple
.bg-purple02 {
  background-color: $purple01;
}

// darkred
.bg-darkred {
  background-color: $darkred;
}

// yellow02
.bg-yellow02 {
  background-color: $yellow01;
}
