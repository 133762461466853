.slick {
  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $navy;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
  &-prev {
    left: 17.5%;
  }
  &-next {
    right: 17.5%;
  }
}

.slick-list {
  overflow: visible;
}